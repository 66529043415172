import React from 'react';
import classNames from 'classnames';

import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { BiInfoCircle, BiPaperPlane } from 'react-icons/bi';

import classes from './style.module.css';

const schema = z.object({
    message: z.string().min(1)
});

const ChatBotInput = ({  isLoading, onSubmit, errorMessage }) => {
    const { formState, register, handleSubmit, reset } = useForm({ resolver: zodResolver(schema), mode: 'onTouched' });
   
    const onQuerySubmit = (data) => {
        onSubmit(data.message);
        reset();
    };

    console.log(errorMessage)

    return ( 
        <div className={classes.messageListInputContainer}>
            {errorMessage && (
                <div className={classes.feedbackFormError}>
                    <BiInfoCircle  />
                    <div className={classes.feedbackFormText}>{errorMessage}</div>
                </div>
            )}

            <form 
                onSubmit={handleSubmit(onQuerySubmit)} 
                className={classNames({
                    [classes.messageListInputForm]: true,
                    [classes.messageListInputFormNormal]: !errorMessage,
                    [classes.messageListInputFormError]: !!errorMessage
                })}
            >
                <input 
                    type="text" 
                    className={classes.messageListInput}
                    {...register("message")}
                    placeholder='Ask me anything'
                />

                <button className={classNames({
                    [classes.messageListInputButton]: true,
                    [classes.messageListInputButtonDisabled]: !formState.isValid 
                })} disabled={isLoading || !formState.isValid} type='submit'>
                    {(isLoading) ? <div className={classes.loader}></div> : <BiPaperPlane />}
                </button>
            </form>
        </div>
     );
}
 
export default ChatBotInput;