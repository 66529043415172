import React from 'react';
import { TABS } from '../../utilities/constants';

import classNames from './style.module.css';

const ChatBotTabButtons = ({ onChangeTab }) => {
    return ( 
        <div className={classNames.chatbotTabButtonsContainer}>
            {TABS.map((tab) => (
                <button 
                    key={tab.key}
                    onClick={() => onChangeTab(tab.key)}
                    className={classNames.chatbotTabButtonsItem}
                >
                    <tab.Icon className={classNames.chatbotTabButtonsIcon} />
                    <div className={classNames.chatbotTabButtonsLabel}>{tab.label}</div>
                </button>
            ))}
        </div>
     );
};
 
export default ChatBotTabButtons;