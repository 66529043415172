import { BsArrowDown } from "react-icons/bs";

import classes from './style.module.css';
import { useScrollToBottom, useSticky } from "react-scroll-to-bottom";

const ChatBotScrollButton = () => {
    const scrollToBottom = useScrollToBottom();
    const sticky = useSticky();

    if (sticky[0]) return null;
    
    return ( 
        <button className={classes.scrollDownButton} onClick={scrollToBottom}>
            <BsArrowDown />
        </button>
    );
}
 
export default ChatBotScrollButton;