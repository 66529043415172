import React from 'react';
import classNames from 'classnames';

import Feedback from './Feedback';
import LastChat from './LastChat';

import { BiChevronRight, BiPaperPlane, BiSearch } from 'react-icons/bi';
import { DB_SECTIONS, DISPLAY_HELP_SECTION, HELP_SECTIONS, TAB_CATEGORIES } from '../../utilities/constants';

import logo from '../../assets/logos/cap_light.svg';
import classes from './style.module.css';

const ChatBotHome = ({ onChangeTab }) => {
    return ( 
        <div className={classes.chatbotHome}>
            <img src={logo} alt="CAP" className={classes.messageListLogo} />

            <h2 className={classes.chatbotHomeTitle}>Hello <span className={classNames({
                [classes.chatbotSendMessageIcon]: true,
                [classes.chatbotHomeTitle]: true,
            })}>there</span>, <br /> How can we help?</h2>
            
            <div className={classes.chatbotRecentMessage}>
                <h3 className={classes.chatbotRecentMessageTitle}>Recent message</h3>
                <LastChat onClickLastChat={() => onChangeTab(TAB_CATEGORIES.MESSAGES)} showBorder={false} />
            </div>

            <button 
                className={classes.chatbotSendMessage}
                onClick={() => onChangeTab(TAB_CATEGORIES.MESSAGES)}
            >
                <div className={classes.chatbotSendMessageText}>Send a message</div>
                <BiPaperPlane className={classes.chatbotSendMessageIcon} />
            </button>

            {DISPLAY_HELP_SECTION && (
                <>
                    <div className={classes.chatbotSearchHelpContainer}>
                        <button 
                            className={classes.chatbotSearchHelp}
                            onClick={() => onChangeTab(TAB_CATEGORIES.HELP)}
                        >
                            <div className={classes.chatbotSearchHelpText}>Search for help</div>
                            <BiSearch />
                        </button>
                        
                        <div>
                            {HELP_SECTIONS.slice(0, 4).map((section) => (
                                <button 
                                    key={section.title}
                                    className={classes.chatbotHelpItem}
                                    onClick={() => onChangeTab(TAB_CATEGORIES.MESSAGES)}
                                >
                                    <div className={classes.chatbotHelpItemText}>{section.title}</div>
                                    <BiChevronRight />
                                </button>
                            ))}
                        </div>
                    </div>

                    {DB_SECTIONS.map((section) => (
                        <button key={section.title} className={classes.chatbotHomeHelpItem}>
                            <div className={classes.chatbotHomeHelpItemTitle}>
                                {section.title}
                            </div>
                            <div className={classes.chatbotHomeHelpItemSubtitle}>
                                {section.body}
                            </div>
                        </button>
                    ))}
                </>
            )}

            <Feedback />
        </div>
    );
};
 
export default ChatBotHome;