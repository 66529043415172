import React from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import { BiChevronRight } from 'react-icons/bi';
import { MdAccountBox, MdChat, MdComputer } from 'react-icons/md';
import { getRelativeTimeFromTimestamp, summarize } from '../../utilities/lib';

import useChatStream from '../../hooks/useChatStream';
import classes from './style.module.css';

const LastChat = ({ onClickLastChat, showBorder = true }) => {
    const { isFetching, data } = useChatStream(false);
    const { t } = useTranslation();

    return (
        <>
            {isFetching ? (
                <div className={classes.lastChatContentLoading}>
                    <div className={classes.lastChatContentIcon}></div>
                    <div className={classes.lastChatContentDetails}>
                        <div className={classes.lastChatContentTitleLoading}></div>
                        <div className={classes.lastChatContentDescriptionLoading}></div>
                    </div>
                    <div className={classes.lastChatContentChevronLoading}></div>
                </div>
            ) : (
                <>
                    <button className={classes.lastChatContainer} onClick={onClickLastChat}>
                        {data.lastChat ? (
                            <div className={classes.lastChatContent}>
                                <div className={classes.lastChatContentIcon}>
                                    {data.lastChat.role === 'assistant' ? <MdComputer className={classes.lastChatContentIconText} /> : <MdAccountBox className={classes.lastChatContentIconText} />}
                                </div>
                                <div className={classNames({
                                    [classes.lastChatContentDetails]: true,
                                    [classes.lastChatContentBordered]: showBorder
                                })}>
                                    <h3 className={classes.lastChatContentTitle}>{summarize(data.lastChat.message)}</h3>
                                    <p className={classes.lastChatContentDescription}>{data.lastChat.role === 'assistant' ? 'CAP' : t('ChatBotLastChat.You')} - {getRelativeTimeFromTimestamp(data.lastChat.created_at)}</p>
                                </div>
                                <BiChevronRight className={classes.lastChatContentChevron} />
                            </div>
                        ): (
                            <div className={classes.lastChatContent}>
                                <div className={classes.lastChatContentIcon}>
                                    <MdChat className={classes.lastChatContentIconText} />
                                </div>
                                <div className={classNames({
                                    [classes.lastChatContentDetails]: true,
                                    [classes.lastChatContentBordered]: showBorder
                                })}>
                                    <h3 className={classes.lastChatContentTitle}>{t('ChatBotLastChat.NoRecentMessages')}</h3>
                                    <p className={classes.lastChatContentDescription}>{t('ChatBotLastChat.TapHereToStartOne')}</p>
                                </div>
                                <BiChevronRight className={classes.lastChatContentChevron} />
                            </div>
                        )}
                    </button>
                </>
            )}
        </>
    );
};

export default LastChat;