import axios from "axios";
import dayjs from "dayjs";

import { useCallback, useEffect, useState } from "react";
import { getChatMessages } from "../services/chat";
import { getChatId } from "../services/session";

const QUERY_COMPLETE_STATES = ['completed', 'failed', 'expired'];

const useChatStream = (refetchStream) => {
    const [data, setData] = useState({ status: 'in_progress', list: [], lastChat: null });
    const [isFetching, setFetching] = useState(false);
    const [error, setError] = useState(null);

    const fetchChats = async () => {
        const chatId = getChatId();
        if (!chatId) return;
                                                                                        
        try {
            setFetching(true);
            const response = await getChatMessages();
            setData(response.data);
        } catch (error) {
            if (axios.isAxiosError(error) && error.status === 404) return;

            setError(error);
        } finally {
            setFetching(false);
        }
    };

    const onAddNewUserMessage = useCallback((message) => {
        const createdAt = dayjs().unix();

        const newMessage = {
            id: createdAt.toString(),
            created_at: createdAt,
            role: 'user',
            content: [ { text: { value: message } }],
        };

        setData(previous => ({ ...previous, list: [...previous.list, newMessage]}))
    }, []);

    useEffect(() => {
        if (!QUERY_COMPLETE_STATES.includes(data.status)) {
            fetchChats();
        }
    }, [data.status]);

    useEffect(() => {
        if (refetchStream) {
            fetchChats();
        }
    }, [refetchStream]);

    return { isFetching, data, error, onAddNewUserMessage };
};

export default useChatStream;