import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { BiPaperPlane } from 'react-icons/bi';
import { motion } from 'framer-motion';

import ChatMessageList from './ChatMessageList';
import classNames from './style.module.css';

import { initializeChat } from '../../services/chat';
import { getChatId, storeChatId } from '../../services/session';

const ChatBotMessageSection = () => {
    const [chatId, setChatId] = useState(null);
    
    const mutation = useMutation({
        mutationFn: initializeChat,
        onSuccess: (result) => {
            const newChatId = result.data._id;
            
            storeChatId(newChatId);
            setChatId(newChatId);
        }
    });

    const renderGeneralMessages = useCallback(() => {
        return (
            <>
                <div className={classNames.messageHeader}>
                    <p className={classNames.messageHeaderText}>Messages</p>
                </div>
                <div className={classNames.messageContent}>
                    <div className={classNames.lastChatContentLoading}>
                        <div className={classNames.lastChatContentIcon}></div>
                        <div className={classNames.lastChatContentDetails}>
                            <div className={classNames.lastChatContentTitleLoading}></div>
                            <div className={classNames.lastChatContentDescriptionLoading}></div>
                        </div>
                        <div className={classNames.lastChatContentChevronLoading}></div>
                    </div>

                    {!chatId && (
                        <div className={classNames.messageButtonContainer}>
                            <motion.button
                                disabled={mutation.isPending}
                                initial={{ opacity: 0 }} 
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }} 
                                whileHover={{ scale: 1.1 }}
                                onClick={() => mutation.mutate()}
                                className={classNames.messageButton}
                                transition={{ type: "spring", stiffness: 260, damping: 20 }}
                            >
                                {mutation.isPending 
                                    ? <div className={classNames.loader}></div>
                                    : (
                                        <>
                                            <span className={classNames.messageButtonText}>Send a message</span>
                                            <BiPaperPlane />
                                        </>
                                    )}
                            </motion.button>
                        </div>
                    )}
                </div>
            </>
        );
    }, [chatId, mutation]);

    useEffect(() => {
        const chatId = getChatId();
        
        if (chatId) setChatId(chatId);
        else mutation.mutate();

        // eslint-disable-next-line
    }, [mutation.mutate]);

    return chatId ? <ChatMessageList /> : renderGeneralMessages();
};

export default ChatBotMessageSection;