import React, { Suspense, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import capLogo from "../assets/logos/cap_dark.svg";

const Home = lazy(() => import("../pages/home"));
const About = lazy(() => import("../pages/About/about"));
const History = lazy(() => import("../pages/About/history"));
const WhoWeAre = lazy(() => import("../pages/About/who-we-are"));
const Leadership = lazy(() => import("../pages/About/leadership"));
const BusinessSegments = lazy(() => import("../pages/BusinessSegments/business-segments"));
const DecorativeCoatings = lazy(() => import("../pages/BusinessSegments/decorative-coatings"));
const IndustrialCoatings = lazy(() => import("../pages/BusinessSegments/industrial-coatings"));
const Dulux = lazy(() => import("../pages/BusinessSegments/dulux"));
const Caplux = lazy(() => import("../pages/BusinessSegments/caplux"));
const Sandtex = lazy(() => import("../pages/BusinessSegments/sandtex"));
const Hempel = lazy(() => import("../pages/BusinessSegments/hempel"));
const Resources = lazy(() => import("../pages/Resources/resources"));
const PaintersAcademy = lazy(() => import("../pages/Resources/painters-academy"));
const Faqs = lazy(() => import ("../pages/Resources/faqs"));
const Investors = lazy(() => import("../pages/Investors/investors"));
const InvestorsAnnualReports = lazy(() => import("../pages/Investors/investors-annual-reports"));
const InvestorsCorporateAnnouncements = lazy(() => import("../pages/Investors/corporate-announcements"));
const InvestorsCorporateGovernance = lazy(() => import("../pages/Investors/investors-corporate-governance"));
const InvestorsFinancialReports = lazy(() => import("../pages/Investors/investors-financial-reports"));
const InvestorsSustainability = lazy(() => import("../pages/Investors/investors-sustainability"));
const InvestorsPolicies = lazy(() => import("../pages/Investors/investors-policies"));
const Careers = lazy(() => import("../pages/Careers/careers"));
const OpenVacancies = lazy(() => import("../pages/Careers/vacancies"));
const EarlyCareers = lazy(() => import("../pages/Careers/early-careers"));
const Media = lazy(() => import("../pages/media"));
const Contact = lazy(() => import("../pages/contact"));
const Policies = lazy(() => import("../pages/policies"));
const ProductInformationFrançais = lazy(() => import("../pages/product-information-français"))

const AppRoutes = () => {
  return (
    <Suspense fallback={
		<div className="loading-container">
			<img
			src={capLogo}
			alt="logo"
			className="logo-loading"
			/>
			</div>
		}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
				<Route path="/about/our-history" element={<History />} />
				<Route path="/about/who-we-are" element={<WhoWeAre />} />
				<Route path="/about/our-leadership" element={<Leadership />} />
        <Route path="/business-segments" element={<BusinessSegments />} />
				<Route path="/business-segments/decorative-coatings" element={<DecorativeCoatings />} />
				<Route path="/business-segments/industrial-coatings" element={<IndustrialCoatings />} />
				<Route path="/business-segments/dulux" element={<Dulux />} />
				<Route path="/business-segments/caplux" element={<Caplux />} />
				<Route path="/business-segments/sandtex" element={<Sandtex />} />
				<Route path="/business-segments/hempel" element={<Hempel />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/resources/painters-academy" element={<PaintersAcademy/>} />
        <Route path="/resources/frequently-asked-questions" element={<Faqs/>} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/investors/annual-reports" element={<InvestorsAnnualReports />} />
        <Route path="/investors/financial-reports" element={<InvestorsFinancialReports />} />
        <Route path="/investors/corporate-announcements" element={<InvestorsCorporateAnnouncements />} />
        <Route path="/investors/corporate-governance" element={<InvestorsCorporateGovernance />} />
        <Route path="/investors/sustainability" element={<InvestorsSustainability />} />
        <Route path="/investors/policies" element={<InvestorsPolicies />} />
        <Route path="/careers" element={<Careers />} />
				<Route path="/careers/vacancies" element={<OpenVacancies />} />
				<Route path="/careers/early-careers" element={<EarlyCareers />} />
        <Route path="/media" element={<Media />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/policies/privacy-policy" element={<Policies policyType="privacy" />} />
        <Route path="/policies/cookie-policy" element={<Policies policyType="cookie" />} />
        <Route path="/product-information-français" element={<ProductInformationFrançais/>}/>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  )
};

export default AppRoutes;