import { getChatId } from "./session";
import http from "./http"

export const initializeChat = () => {
    return http.post('/chat/initialize');
};

export const sendChatMessage = (data) => {
    return http.post(`/chat/${getChatId()}/ask`, { message: data.message });
};

export const getChatMessages = () => {
    return http.get(`/chat/${getChatId()}`);
};

export const saveFeedback = (feedback) => {
    return http.post('/feedback', feedback);
}