import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AnimatePresence, motion } from "framer-motion"
import { BiChevronDown } from 'react-icons/bi';
import { HiChatAlt2 } from 'react-icons/hi';

import { ChatBotTabButtons, ChatBotHelpSection, ChatBotMessageSection } from '.';
import { DISPLAY_HELP_SECTION, TAB_CATEGORIES } from '../../utilities/constants';

import ChatBotHome from './ChatBotHome';
import classes from './style.module.css';
import features from '../../utilities/features';

const ChatBot = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const [activeTab, setActiveTab] = useState(TAB_CATEGORIES.HOME);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTooltipVisible(true);
        }, 3000);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (isTooltipVisible) {
            const timeout = setTimeout(() => {
                setTooltipVisible(false);
            }, 30_000);
    
            return () => clearTimeout(timeout);
        }
    }, [isTooltipVisible]);

    if (!features.chatbot) return null;

    return ( 
        <div className={classes.chatbotContainer}>
            <AnimatePresence>
                {isModalOpen && (
                    <>
                        <motion.div 
                            key="chatbot"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className={classes.chatbotOverlay}
                            onClick={() => setModalOpen(false)}
                        >
                        </motion.div>
                        
                        <motion.div 
                            initial={{ transform: "translateY(100%)", opacity: 0, scale: 0 }}
                            animate={{ transform: "translateY(0)", opacity: 1, scale: 1 }}
                            exit={{ transform: "translateY(100%)", opacity: 0 }}
                            className={classes.chatbotContent}
                        >
                            <div className={classes.chatbotContentMain}>
                                {activeTab === TAB_CATEGORIES.HOME && <ChatBotHome onChangeTab={setActiveTab} />}
                                {DISPLAY_HELP_SECTION && activeTab === TAB_CATEGORIES.HELP && <ChatBotHelpSection />}
                                {activeTab === TAB_CATEGORIES.MESSAGES && <ChatBotMessageSection />}
                            </div>
                            <ChatBotTabButtons onChangeTab={(tab) => setActiveTab(tab)} />
                        </motion.div>
                    </>
                )}
            </AnimatePresence>

            <div className={classNames([classes.chatbotButtonContainer, 'd-flex justify-content-center align-items-center'])}>
                <AnimatePresence>
                    {isTooltipVisible && (
                        <motion.div 
                            key="tooltip"
                            className={classes.chatbotTooltip}
                            initial={{ transform: "translateX(-100%)", opacity: 0, scale: 0 }}
                            animate={{ transform: "translateX(0)", opacity: 1, scale: 1 }}
                            exit={{ transform: "translateX(-100%)", opacity: 0, scale: 0 }}
                        >
                            <div>We're online!</div>
                            <p>How may I help you today?</p>
                        </motion.div>
                    )}
                </AnimatePresence>

                <motion.button
                    className={classes.chatbotButton}
                    initial={{ scale: 0 }}
                    whileHover={{ rotate: 360 }}
                    whileTap={{ scale: 1.1 }}
                    animate={{ scale: 1 }}
                    onClick={() => setModalOpen((previous) => !previous)}
                    transition={{ type: "spring", stiffness: 260, damping: 20 }}
                >
                    {isModalOpen ? <BiChevronDown className={classes.chatbotButtonText} /> : <HiChatAlt2 className={classes.chatbotButtonText} />}
                </motion.button>
            </div>
        </div>
    );
};
 
export default ChatBot;